/* ----------- iPhone ----------- */

/* #region  iPhone 4 and 4S */

/* Portrait and Landscape */
@media only screen and (minwidth: 320px) and (maxwidth: 480px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (minwidth: 320px) and (maxwidth: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (minwidth: 320px) and (maxwidth: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* #endregion */

/* #region  iPhone 5, 5S, 5C and 5SE */

/* Portrait and Landscape */
@media only screen and (minwidth: 320px) and (maxwidth: 568px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (minwidth: 320px) and (maxwidth: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (minwidth: 320px) and (maxwidth: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* #endregion */

/* #region  iPhone 6, 6S, 7 and 8 */

/* Portrait and Landscape */
@media only screen and (minwidth: 375px) and (maxwidth: 667px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (minwidth: 375px) and (maxwidth: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (minwidth: 375px) and (maxwidth: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* #endregion */

/* #region  iPhone 6+, 7+ and 8+ */

/* Portrait and Landscape */
@media only screen and (minwidth: 414px) and (maxwidth: 736px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* Portrait */
@media only screen and (minwidth: 414px) and (maxwidth: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (minwidth: 414px) and (maxwidth: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}

/* #endregion */

/* #region  iPhone X */

/* Portrait and Landscape */
@media only screen and (minwidth: 375px) and (maxwidth: 812px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* Portrait */
@media only screen and (minwidth: 375px) and (maxwidth: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (minwidth: 375px) and (maxwidth: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}

/* #endregion */

/* ----------- Galaxy ----------- */

/* #region   Galaxy S3  */

/* Portrait and Landscape */
@media screen and (device-width: 360px) and (device-height: 640px) and (device-pixel-ratio: 2) {
}

/* Portrait */
@media screen and (device-width: 320px) and (device-height: 640px) and (device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media screen and (device-width: 320px) and (device-height: 640px) and (device-pixel-ratio: 2) and (orientation: landscape) {
}

/* #endregion */

/* #region  Galaxy S4, S5 and Note 3 */

/* Portrait and Landscape */
@media screen and (device-width: 320px) and (device-height: 640px) and (device-pixel-ratio: 3) {
}

/* Portrait */
@media screen and (device-width: 320px) and (device-height: 640px) and (device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media screen and (device-width: 320px) and (device-height: 640px) and (device-pixel-ratio: 3) and (orientation: landscape) {
}

/* #endregion */

/* #region  Galaxy S6 */

/* Portrait and Landscape */
@media screen and (device-width: 360px) and (device-height: 640px) and (device-pixel-ratio: 4) {
}

/* Portrait */
@media screen and (device-width: 360px) and (device-height: 640px) and (device-pixel-ratio: 4) and (orientation: portrait) {
}

/* Landscape */
@media screen and (device-width: 360px) and (device-height: 640px) and (device-pixel-ratio: 4) and (orientation: landscape) {
}

/* #endregion */

/* ----------- Google Pixel ----------- */

/* #region  Google Pixel */

/* Portrait and Landscape */
@media screen and (device-width: 360px) and (device-height: 640px) and (device-pixel-ratio: 3) {
}

/* Portrait */
@media screen and (device-width: 360px) and (device-height: 640px) and (device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media screen and (device-width: 360px) and (device-height: 640px) and (device-pixel-ratio: 3) and (orientation: landscape) {
}

/* #endregion */

/* #region  Google Pixel XL */

/* Portrait and Landscape */
@media screen and (device-width: 360px) and (device-height: 640px) and (device-pixel-ratio: 4) {
}

/* Portrait */
@media screen and (device-width: 360px) and (device-height: 640px) and (device-pixel-ratio: 4) and (orientation: portrait) {
}

/* Landscape */
@media screen and (device-width: 360px) and (device-height: 640px) and (device-pixel-ratio: 4) and (orientation: landscape) {
}

/* #endregion */

/* ----------- HTC One ----------- */

/* #region  HTC One */

/* Portrait and Landscape */
@media screen and (device-width: 360px) and (device-height: 640px) and (device-pixel-ratio: 3) {
}

/* Portrait */
@media screen and (device-width: 360px) and (device-height: 640px) and (device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media screen and (device-width: 360px) and (device-height: 640px) and (device-pixel-ratio: 3) and (orientation: landscape) {
}

/* #endregion */

/* ----------- Windows Phone ----------- */

/* #region  Windows Phone */

/* Portrait and Landscape */
@media screen and (device-width: 480px) and (device-height: 800px) {
}

/* Portrait */
@media screen and (device-width: 480px) and (device-height: 800px) and (orientation: portrait) {
}

/* Landscape */
@media screen and (device-width: 480px) and (device-height: 800px) and (orientation: landscape) {
}

/* #endregion */

/* ----------- iPad ----------- */

/* #region  iPad 1, 2, Mini and Air */

/* Portrait and Landscape */
@media only screen and (minwidth: 768px) and (maxwidth: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Portrait */
@media only screen and (minwidth: 768px) and (maxwidth: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Landscape */
@media only screen and (minwidth: 768px) and (maxwidth: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}

/* #endregion */

/* #region  iPad 3, 4 and Pro 9.7" */

/* Portrait and Landscape */
@media only screen and (minwidth: 768px) and (maxwidth: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (minwidth: 768px) and (maxwidth: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
@media only screen and (minwidth: 768px) and (maxwidth: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* #endregion */

/* #region  iPad Pro 10.5" */

/* Portrait and Landscape */
@media only screen and (minwidth: 834px) and (maxwidth: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */

/* Declare the same value for min- and max-width to avoid colliding with desktops */

/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106 */
@media only screen and (minwidth: 834px) and (maxwidth: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */

/* Declare the same value for min- and max-width to avoid colliding with desktops */

/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106 */
@media only screen and (minwidth: 1112px) and (maxwidth: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* #endregion */

/* #region  iPad Pro 12.9" */

/* Portrait and Landscape */
@media only screen and (minwidth: 1024px) and (maxwidth: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */

/* Declare the same value for min- and max-width to avoid colliding with desktops */

/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106 */
@media only screen and (minwidth: 1024px) and (maxwidth: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */

/* Declare the same value for min- and max-width to avoid colliding with desktops */

/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106 */
@media only screen and (minwidth: 1366px) and (maxwidth: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* #endregion */

/* ----------- Galaxy Tablets ----------- */

/* #region  Galaxy Tab 2 */

/* Portrait and Landscape */
@media (min-device-width: 800px) and (max-device-width: 1280px) {
}

/* Portrait */
@media (max-device-width: 800px) and (orientation: portrait) {
}

/* Landscape */
@media (max-device-width: 1280px) and (orientation: landscape) {
}

/* #endregion */

/* #region  Galaxy Tab S */

/* Portrait and Landscape */
@media (minwidth: 800px) and (maxwidth: 1280px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media (maxwidth: 800px) and (orientation: portrait) and (min-device-pixel-ratio: 2) {
}

/* Landscape */
@media (maxwidth: 1280px) and (orientation: landscape) and (min-device-pixel-ratio: 2) {
}

/* #endregion */

/* ----------- Nexus Tablets ----------- */

/* #region  Nexus 7 */

/* Portrait and Landscape */
@media screen and (device-width: 601px) and (device-height: 906px) and (minpixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) {
}

/* Portrait */
@media screen and (device-width: 601px) and (device-height: 906px) and (minpixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) and (orientation: portrait) {
}

/* Landscape */
@media screen and (device-width: 601px) and (device-height: 906px) and (minpixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) and (orientation: landscape) {
}

/* #endregion */

/* #region  Nexus 9 */

/* Portrait and Landscape */
@media screen and (device-width: 1536px) and (device-height: 2048px) and (minpixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) {
}

/* Portrait */
@media screen and (device-width: 1536px) and (device-height: 2048px) and (minpixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) and (orientation: portrait) {
}

/* Landscape */
@media screen and (device-width: 1536px) and (device-height: 2048px) and (minpixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) and (orientation: landscape) {
}

/* #endregion */

/* ----------- Kindle Fire Tablets ----------- */

/* #region  Kindle Fire HD 7" */

/* Portrait and Landscape */
@media only screen and (minwidth: 800px) and (maxwidth: 1280px) and (-webkit-min-device-pixel-ratio: 1.5) {
}

/* Portrait */
@media only screen and (minwidth: 800px) and (maxwidth: 1280px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (minwidth: 800px) and (maxwidth: 1280px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape) {
}

/* #endregion */

/* #region  Kindle Fire HD 8.9" */

/* Portrait and Landscape */
@media only screen and (minwidth: 1200px) and (maxwidth: 1600px) and (-webkit-min-device-pixel-ratio: 1.5) {
}

/* Portrait */
@media only screen and (minwidth: 1200px) and (maxwidth: 1600px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (minwidth: 1200px) and (maxwidth: 1600px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape) {
}

/* #endregion */
