$icomoon-font-family: "mf" !default;
$icomoon-font-path: "../assets/fonts/mf" !default;

$icon-Uploaded: "\e933";
$icon-Uploading: "\e934";
$icon-Upload-error: "\e935";
$icon-Pending: "\e936";
$icon-Account: "\e96b";
$icon-Active: "\e974";
$icon-Add-Users: "\e969";
$icon-Arrow-Ar: "\e93a";
$icon-Arrow-Diagonal: "\e967";
$icon-Arrow-En: "\e954";
$icon-Attach: "\e906";
$icon-Bank-Statement: "\e985";
$icon-Bank-Transfer: "\e950";
$icon-Call: "\e960";
$icon-Camera: "\e970";
$icon-Car-License: "\e90c";
$icon-Cash: "\e94a";
$icon-Cash-out--Withdraw: "\e980";
$icon-Cash-out-date: "\e939";
$icon-Change-Cash-Out-Method: "\e984";
$icon-Change-Pay-in-Method: "\e983";
$icon-Change-contract-method: "\e963";
$icon-Change-payment-methodChange-payment-method: "\e981";
$icon-Change-payment-options: "\e96f";
$icon-Chashback: "\e945";
$icon-Check-Icon-Double: "\e971";
$icon-Check-icon: "\e941";
$icon-Checked-path1: "\e900";
$icon-Checked-path2: "\e901";
$icon-Chevron-Down: "\e928";
$icon-Chevron-Left: "\e944";
$icon-Chevron-Right: "\e93b";
$icon-Chevron-Up: "\e920";
$icon-Choose-rewards: "\e913";
$icon-Circle-Fees: "\e97b";
$icon-Close: "\e953";
$icon-Club-ID: "\e90b";
$icon-Contact-Delivery-Fees: "\e987";
$icon-Copy: "\e93e";
$icon-Credit-Card: "\e94c";
$icon-Credit-Point-system-1: "\e92e";
$icon-Credit-Point-system-2: "\e91f";
$icon-Credit-Profile: "\e96c";
$icon-Date: "\e930";
$icon-Deduced-from-payout: "\e914";
$icon-Delete: "\e927";
$icon-Digital-Wallet: "\e949";
$icon-Documents: "\e93f";
$icon-Download: "\e937";
$icon-Duration: "\e943";
$icon-Earn-points: "\e915";
$icon-Edit-AR: "\e904";
$icon-Edit-EN: "\e905";
$icon-Email-Verification: "\e90d";
$icon-End-Date: "\e925";
$icon-Export-payments-history: "\e96d";
$icon-Eye: "\e924";
$icon-Eye-Disable: "\e940";
$icon-Fawry: "\e94b";
$icon-Fawry-2: "\e94d";
$icon-Fawry-Plus: "\e948";
$icon-Fawry-Plus-2: "\e94f";
$icon-Feedback: "\e95f";
$icon-Fees: "\e93d";
$icon-Fellow-Points-Outline: "\e90e";
$icon-Fellow-Points-Outline-Solid: "\e975";
$icon-Filter: "\e95b";
$icon-Gift: "\e966";
$icon-HR-Letter: "\e902";
$icon-Help: "\e95c";
$icon-Help-Question: "\e929";
$icon-Increase-pay-in-limit: "\e916";
$icon-Info: "\e957";
$icon-Join-Circle-AR: "\e907";
$icon-Join-Circle-EN: "\e908";
$icon-Join-first-slots: "\e917";
$icon-Join-similar-circle: "\e968";
$icon-Language: "\e92b";
$icon-Link: "\e931";
$icon-Live-Chat: "\e95e";
$icon-Lock: "\e938";
$icon-Log-Out: "\e942";
$icon-Mega-Phone: "\e961";
$icon-Menu: "\e958";
$icon-Merchant-Acceptance: "\e97a";
$icon-Mobile: "\e972";
$icon-MoneyFellows-icon: "\e932";
$icon-Monthly-Income: "\e90f";
$icon-Monthly-Pay-in: "\e97c";
$icon-More: "\e956";
$icon-My-Circles: "\e965";
$icon-My-Points: "\e989";
$icon-National-ID: "\e909";
$icon-Notifications: "\e96e";
$icon-Offer: "\e921";
$icon-Paid-duration: "\e973";
$icon-Pay-all-payments: "\e986";
$icon-Pay-in: "\e97d";
$icon-Pay-in-advance: "\e988";
$icon-Payment-History: "\e93c";
$icon-Payments: "\e982";
$icon-Pension: "\e903";
$icon-Placeholder: "\e919";
$icon-Plus: "\e95d";
$icon-Points-History: "\e91a";
$icon-Premium-support: "\e91b";
$icon-Program-Terms: "\e91c";
$icon-Promo: "\e91d";
$icon-Re-capture: "\e92c";
$icon-Refund-amount: "\e979";
$icon-Refund-date: "\e978";
$icon-Remaining-amount: "\e95a";
$icon-Remove: "\e923";
$icon-Salary-deduction: "\e951";
$icon-Search: "\e92d";
$icon-Send: "\e90a";
$icon-Settings: "\e962";
$icon-Share: "\e946";
$icon-Social: "\e910";
$icon-Sorting: "\e959";
$icon-Start-Date: "\e97f";
$icon-Stepper: "\e955";
$icon-Text-field-expander: "\e947";
$icon-Thumbs-Down: "\e922";
$icon-Thumbs-Up: "\e92a";
$icon-Total-paid: "\e96a";
$icon-Upload: "\e97e";
$icon-Upload-Document: "\e91e";
$icon-User-Removed: "\e964";
$icon-Utility-bill: "\e911";
$icon-Voucher-Amount: "\e952";
$icon-Voucher-Expire: "\e94e";
$icon-Voucher-Generated: "\e918";
$icon-Wallet: "\e912";
$icon-Warning: "\e92f";
$icon-Work-ID: "\e926";

