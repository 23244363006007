@import "./functions";

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw); // to get the unit of font for example 'px'
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    // if units are the same
    & {
      font-size: $min-font-size;

      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

// $grid_system_unit: 8px;
// @mixin margin($top, $right, $bottom, $left) {
//   margin: $top * $grid_system_unit $right * $grid_system_unit $bottom *
//     $grid_system_unit $left * $grid_system_unit;
// }

// @mixin margin-left($value) {
//   margin-left: $value * $grid_system_unit;
// }

// @mixin margin-right($value) {
//   margin-right: $value * $grid_system_unit;
// }

// @mixin padding($top, $right, $bottom, $left) {
//   padding: $top * $grid_system_unit $right * $grid_system_unit $bottom *
//     $grid_system_unit $left * $grid_system_unit;
// }

// @mixin padding-left($value) {
//   padding-left: $value * $grid_system_unit;
// }

// @mixin padding-right($value) {
//   padding-right: $value * $grid_system_unit;
// }
