@use "sass:list";

$grid_system_unit: 8px;
$grid_system_maximum_values: 10;

//init values array with special units
$values: (("0-25", 0.25 * $grid_system_unit), ("0-5", 0.5 * $grid_system_unit),("1-5", 1.5 * $grid_system_unit),("3-5", 3.5 * $grid_system_unit));

$count: 0;
@while $count < $grid_system_maximum_values {
  $values: list.append($values, ($count, $count * $grid_system_unit));
  $count: $count + 1;
}


/* start margin script */
//margin 
@each $i in $values {
  .m-#{nth($i, 1)} {
    margin: nth($i, 2);
  }
}

//margin-vertical
@each $i in $values {
  .m-v-#{nth($i, 1)} {
    margin: nth($i, 2) 0px;
  }
}

//margin-horizontal
@each $i in $values {
  .m-h-#{nth($i, 1)} {
    margin: 0px nth($i, 2);
  }
}

//margin-left
@each $i in $values {
  html[dir="ltr"] {
    .ml-#{nth($i, 1)} {
      margin-left: nth($i, 2);
    }
  }
  html[dir="rtl"] {
    .ml-#{nth($i, 1)} {
      margin-right: nth($i, 2);
    }
  }
}

//margin-right
@each $i in $values {
  html[dir="ltr"] {
    .mr-#{nth($i, 1)} {
      margin-right: nth($i, 2);
    }
  }
  html[dir="rtl"] {
    .mr-#{nth($i, 1)} {
      margin-left: nth($i, 2);
    }
  }
}

//margin-top
@each $i in $values {
  .mt-#{nth($i, 1)} {
    margin-top: nth($i, 2);
  }
}

//margin-bottom
@each $i in $values {
  .mb-#{nth($i, 1)} {
    margin-bottom: nth($i, 2);
  }
}

/* start padding script */

//padding
@each $i in $values {
  .p-#{nth($i, 1)} {
    padding: nth($i, 2);
  }
}

//padding-vertical
@each $i in $values {
  .p-v-#{nth($i, 1)} {
    padding: nth($i, 2) 0px;
  }
}

//padding-horizontal
@each $i in $values {
  .p-h-#{nth($i, 1)} {
    padding: 0px nth($i, 2);
  }
}

//padding-left
@each $i in $values {
  html[dir="ltr"] {
    .pl-#{nth($i, 1)} {
      padding-left: nth($i, 2);
    }
  }
  html[dir="rtl"] {
    .pl-#{nth($i, 1)} {
      padding-right: nth($i, 2);
    }
  }
}

//padding-right
@each $i in $values {
  html[dir="ltr"] {
    .pr-#{nth($i, 1)} {
      padding-right: nth($i, 2);
    }
  }
  html[dir="rtl"] {
    .pr-#{nth($i, 1)} {
      padding-left: nth($i, 2);
    }
  }
}

//padding-top
@each $i in $values {
  .pt-#{nth($i, 1)} {
    padding-top: nth($i, 2);
  }
}

//padding-bottom
@each $i in $values {
  .pb-#{nth($i, 1)} {
    padding-bottom: nth($i, 2);
  }
}
