/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import 'layout/_grid';

* {
  outline: none;
}
//on-boarding slider bullets style
.swiper-container-horizontal > .swiper-pagination-bullets {
  display: inline-flex;
  padding: 0 24px;
  bottom: 46px;
  z-index: 0;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 6px;
  opacity: 1;
}
// display felx Center
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

// display felx Center
.flex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

[dir='rtl'] {
  .icon-flipable {
    -webkit-transform: scale(-1, 1);
    -moz-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }
  // As numbers are in En while app is Ar please use class for phone numbers
  .phone-number-formatter {
    direction: ltr;
    letter-spacing: 1px;
  }
}

// display inline-block vertical middle
.middle-inline-block {
  display: inline-block;
  vertical-align: middle;
}

.v-extra-space {
  height: $grid_system_unit * 10;
}

.text-center {
  text-align: center;
}

//Considered the tooltip to be included within ion-icon or span
//Can be set as generic by using *[tooltip] but it's not prefer
span[appTooltip],
span[ng-reflect-appTooltip],
ion-icon[appTooltip],
ion-icon[ng-reflect-appTooltip] {
  pointer-events: all !important;
  text-align: center;
}

#hpp-div-placeholder {
  .geideaHppHeader {
    display: none;
  }
  .geideaMerchantLogo {
    z-index: 1 !important;
  }
  input {
    border-radius: 8px !important;
  }
  //   position: relative !important;
}

@media print {
  .ion-page {
    display: initial !important;
    position: initial !important;
  }
  body {
    position: initial !important;
    max-height: initial !important;
    overflow: auto !important;
  }
  ion-router-outlet {
    overflow: auto !important;
    contain: none !important;
    position: initial !important;
  }
  ion-content {
    --offset-bottom: unset !important;
    display: unset !important;
    position: unset !important;
  }
}
