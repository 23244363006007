/* define CSS Variables to be modified by JavaScript */
:root {
  --distaniceFrom: 0;
  --distaniceTo: 0;
}

@keyframes setDistanceFromTop {
  from {
    top: var(--distaniceFrom);
  }

  to {
    top: var(--distaniceTo);
  }
}

.runDistanceAnimation {
  animation-name: setDistanceFromTop;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    //  padding-top: 32px;
     height: 100%;
  }
}

@keyframes slideUp {
  from {
     transform: translateY(endYposition);
     opacity: 1;
  }
  to {
    transform: translateY(startYposition);
    opacity: 0.5;
    // height: 0;
  }
}

.hiding {
  animation: 3.5s ease-in-out fadeOut;
}

.showing {
  animation: 3.5s ease-in-out fadeIn;
  opacity: 1;
}

.slideDown {
  animation:slideDown 0.5s ease-in-out;
  animation-delay: 0s;
}

.slideUp {
  animation:slideUp 0.5s ease-in-out;
  animation-delay: 0s;
}