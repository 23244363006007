@import 'variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?mj9ogj');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?mj9ogj#iefix')
      format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?mj9ogj')
      format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?mj9ogj')
      format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?mj9ogj##{$icomoon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ion-icon {
  &:before {
    display: grid;
    place-content: center;
    height: 100%;
  }
}

.icon-Uploaded {
  &:before {
    content: $icon-Uploaded;
  }
}
.icon-Uploading {
  &:before {
    content: $icon-Uploading;
  }
}
.icon-Upload-error {
  &:before {
    content: $icon-Upload-error;
  }
}
.icon-Pending {
  &:before {
    content: $icon-Pending;
  }
}
.icon-Account {
  &:before {
    content: $icon-Account;
  }
}
.icon-Active {
  &:before {
    content: $icon-Active;
  }
}
.icon-Add-Users {
  &:before {
    content: $icon-Add-Users;
  }
}
.icon-Arrow-Ar {
  &:before {
    content: $icon-Arrow-Ar;
  }
}
.icon-Arrow-Diagonal {
  &:before {
    content: $icon-Arrow-Diagonal;
  }
}
.icon-Arrow-En {
  &:before {
    content: $icon-Arrow-En;
  }
}
.icon-Attach {
  &:before {
    content: $icon-Attach;
  }
}
.icon-Bank-Statement {
  &:before {
    content: $icon-Bank-Statement;
  }
}
.icon-Bank-Transfer {
  &:before {
    content: $icon-Bank-Transfer;
  }
}
.icon-Call {
  &:before {
    content: $icon-Call;
  }
}
.icon-Camera {
  &:before {
    content: $icon-Camera;
  }
}
.icon-Car-License {
  &:before {
    content: $icon-Car-License;
  }
}
.icon-Cash {
  &:before {
    content: $icon-Cash;
  }
}
.icon-Cash-out--Withdraw {
  &:before {
    content: $icon-Cash-out--Withdraw;
  }
}
.icon-Cash-out-date {
  &:before {
    content: $icon-Cash-out-date;
  }
}
.icon-Change-Cash-Out-Method {
  &:before {
    content: $icon-Change-Cash-Out-Method;
  }
}
.icon-Change-Pay-in-Method {
  &:before {
    content: $icon-Change-Pay-in-Method;
  }
}
.icon-Change-contract-method {
  &:before {
    content: $icon-Change-contract-method;
  }
}
.icon-Change-payment-methodChange-payment-method {
  &:before {
    content: $icon-Change-payment-methodChange-payment-method;
  }
}
.icon-Change-payment-options {
  &:before {
    content: $icon-Change-payment-options;
  }
}
.icon-Chashback {
  &:before {
    content: $icon-Chashback;
  }
}
.icon-Check-Icon-Double {
  &:before {
    content: $icon-Check-Icon-Double;
  }
}
.icon-Check-icon {
  &:before {
    content: $icon-Check-icon;
  }
}
.icon-Checked .path1 {
  &:before {
    content: $icon-Checked-path1;
    color: rgb(37, 41, 64);
  }
}
.icon-Checked .path2 {
  &:before {
    content: $icon-Checked-path2;
    margin-left: -1em;
    color: rgb(247, 247, 255);
  }
}
.icon-Chevron-Down {
  &:before {
    content: $icon-Chevron-Down;
  }
}
.icon-Chevron-Left {
  &:before {
    content: $icon-Chevron-Left;
  }
}
.icon-Chevron-Right {
  &:before {
    content: $icon-Chevron-Right;
  }
}
.icon-Chevron-Up {
  &:before {
    content: $icon-Chevron-Up;
  }
}
.icon-Choose-rewards {
  &:before {
    content: $icon-Choose-rewards;
  }
}
.icon-Circle-Fees {
  &:before {
    content: $icon-Circle-Fees;
  }
}
.icon-Close {
  &:before {
    content: $icon-Close;
  }
}
.icon-Club-ID {
  &:before {
    content: $icon-Club-ID;
  }
}
.icon-Contact-Delivery-Fees {
  &:before {
    content: $icon-Contact-Delivery-Fees;
  }
}
.icon-Copy {
  &:before {
    content: $icon-Copy;
  }
}
.icon-Credit-Card {
  &:before {
    content: $icon-Credit-Card;
  }
}
.icon-Credit-Point-system-1 {
  &:before {
    content: $icon-Credit-Point-system-1;
  }
}
.icon-Credit-Point-system-2 {
  &:before {
    content: $icon-Credit-Point-system-2;
  }
}
.icon-Credit-Profile {
  &:before {
    content: $icon-Credit-Profile;
  }
}
.icon-Date {
  &:before {
    content: $icon-Date;
  }
}
.icon-Deduced-from-payout {
  &:before {
    content: $icon-Deduced-from-payout;
  }
}
.icon-Delete {
  &:before {
    content: $icon-Delete;
  }
}
.icon-Digital-Wallet {
  &:before {
    content: $icon-Digital-Wallet;
  }
}
.icon-Documents {
  &:before {
    content: $icon-Documents;
  }
}
.icon-Download {
  &:before {
    content: $icon-Download;
  }
}
.icon-Duration {
  &:before {
    content: $icon-Duration;
  }
}
.icon-Earn-points {
  &:before {
    content: $icon-Earn-points;
  }
}
.icon-Edit-AR {
  &:before {
    content: $icon-Edit-AR;
  }
}
.icon-Edit-EN {
  &:before {
    content: $icon-Edit-EN;
  }
}
.icon-Email-Verification {
  &:before {
    content: $icon-Email-Verification;
  }
}
.icon-End-Date {
  &:before {
    content: $icon-End-Date;
  }
}
.icon-Export-payments-history {
  &:before {
    content: $icon-Export-payments-history;
  }
}
.icon-Eye {
  &:before {
    content: $icon-Eye;
  }
}
.icon-Eye-Disable {
  &:before {
    content: $icon-Eye-Disable;
  }
}
.icon-Fawry {
  &:before {
    content: $icon-Fawry;
  }
}
.icon-Fawry-2 {
  &:before {
    content: $icon-Fawry-2;
  }
}
.icon-Fawry-Plus {
  &:before {
    content: $icon-Fawry-Plus;
  }
}
.icon-Fawry-Plus-2 {
  &:before {
    content: $icon-Fawry-Plus-2;
  }
}
.icon-Feedback {
  &:before {
    content: $icon-Feedback;
  }
}
.icon-Fees {
  &:before {
    content: $icon-Fees;
  }
}
.icon-Fellow-Points-Outline {
  &:before {
    content: $icon-Fellow-Points-Outline;
  }
}
.icon-Fellow-Points-Outline-Solid {
  &:before {
    content: $icon-Fellow-Points-Outline-Solid;
  }
}
.icon-Filter {
  &:before {
    content: $icon-Filter;
  }
}
.icon-Gift {
  &:before {
    content: $icon-Gift;
  }
}
.icon-HR-Letter {
  &:before {
    content: $icon-HR-Letter;
  }
}
.icon-Help {
  &:before {
    content: $icon-Help;
  }
}
.icon-Help-Question {
  &:before {
    content: $icon-Help-Question;
  }
}
.icon-Increase-pay-in-limit {
  &:before {
    content: $icon-Increase-pay-in-limit;
  }
}
.icon-Info {
  &:before {
    content: $icon-Info;
  }
}
.icon-Join-Circle-AR {
  &:before {
    content: $icon-Join-Circle-AR;
  }
}
.icon-Join-Circle-EN {
  &:before {
    content: $icon-Join-Circle-EN;
  }
}
.icon-Join-first-slots {
  &:before {
    content: $icon-Join-first-slots;
  }
}
.icon-Join-similar-circle {
  &:before {
    content: $icon-Join-similar-circle;
  }
}
.icon-Language {
  &:before {
    content: $icon-Language;
  }
}
.icon-Link {
  &:before {
    content: $icon-Link;
  }
}
.icon-Live-Chat {
  &:before {
    content: $icon-Live-Chat;
  }
}
.icon-Lock {
  &:before {
    content: $icon-Lock;
  }
}
.icon-Log-Out {
  &:before {
    content: $icon-Log-Out;
  }
}
.icon-Mega-Phone {
  &:before {
    content: $icon-Mega-Phone;
  }
}
.icon-Menu {
  &:before {
    content: $icon-Menu;
  }
}
.icon-Merchant-Acceptance {
  &:before {
    content: $icon-Merchant-Acceptance;
  }
}
.icon-Mobile {
  &:before {
    content: $icon-Mobile;
  }
}
.icon-MoneyFellows-icon {
  &:before {
    content: $icon-MoneyFellows-icon;
  }
}
.icon-Monthly-Income {
  &:before {
    content: $icon-Monthly-Income;
  }
}
.icon-Monthly-Pay-in {
  &:before {
    content: $icon-Monthly-Pay-in;
  }
}
.icon-More {
  &:before {
    content: $icon-More;
  }
}
.icon-My-Circles {
  &:before {
    content: $icon-My-Circles;
  }
}
.icon-My-Points {
  &:before {
    content: $icon-My-Points;
  }
}
.icon-National-ID {
  &:before {
    content: $icon-National-ID;
  }
}
.icon-Notifications {
  &:before {
    content: $icon-Notifications;
  }
}
.icon-Offer {
  &:before {
    content: $icon-Offer;
  }
}
.icon-Paid-duration {
  &:before {
    content: $icon-Paid-duration;
  }
}
.icon-Pay-all-payments {
  &:before {
    content: $icon-Pay-all-payments;
  }
}
.icon-Pay-in {
  &:before {
    content: $icon-Pay-in;
  }
}
.icon-Pay-in-advance {
  &:before {
    content: $icon-Pay-in-advance;
  }
}
.icon-Payment-History {
  &:before {
    content: $icon-Payment-History;
  }
}
.icon-Payments {
  &:before {
    content: $icon-Payments;
  }
}
.icon-Pension {
  &:before {
    content: $icon-Pension;
  }
}
.icon-Placeholder {
  &:before {
    content: $icon-Placeholder;
  }
}
.icon-Plus {
  &:before {
    content: $icon-Plus;
  }
}
.icon-Points-History {
  &:before {
    content: $icon-Points-History;
  }
}
.icon-Premium-support {
  &:before {
    content: $icon-Premium-support;
  }
}
.icon-Program-Terms {
  &:before {
    content: $icon-Program-Terms;
  }
}
.icon-Promo {
  &:before {
    content: $icon-Promo;
  }
}
.icon-Re-capture {
  &:before {
    content: $icon-Re-capture;
  }
}
.icon-Refund-amount {
  &:before {
    content: $icon-Refund-amount;
  }
}
.icon-Refund-date {
  &:before {
    content: $icon-Refund-date;
  }
}
.icon-Remaining-amount {
  &:before {
    content: $icon-Remaining-amount;
  }
}
.icon-Remove {
  &:before {
    content: $icon-Remove;
  }
}
.icon-Salary-deduction {
  &:before {
    content: $icon-Salary-deduction;
  }
}
.icon-Search {
  &:before {
    content: $icon-Search;
  }
}
.icon-Send {
  &:before {
    content: $icon-Send;
  }
}
.icon-Settings {
  &:before {
    content: $icon-Settings;
  }
}
.icon-Share {
  &:before {
    content: $icon-Share;
  }
}
.icon-Social {
  &:before {
    content: $icon-Social;
  }
}
.icon-Sorting {
  &:before {
    content: $icon-Sorting;
  }
}
.icon-Start-Date {
  &:before {
    content: $icon-Start-Date;
  }
}
.icon-Stepper {
  &:before {
    content: $icon-Stepper;
  }
}
.icon-Text-field-expander {
  &:before {
    content: $icon-Text-field-expander;
  }
}
.icon-Thumbs-Down {
  &:before {
    content: $icon-Thumbs-Down;
  }
}
.icon-Thumbs-Up {
  &:before {
    content: $icon-Thumbs-Up;
  }
}
.icon-Total-paid {
  &:before {
    content: $icon-Total-paid;
  }
}
.icon-Upload {
  &:before {
    content: $icon-Upload;
  }
}
.icon-Upload-Document {
  &:before {
    content: $icon-Upload-Document;
  }
}
.icon-User-Removed {
  &:before {
    content: $icon-User-Removed;
  }
}
.icon-Utility-bill {
  &:before {
    content: $icon-Utility-bill;
  }
}
.icon-Voucher-Amount {
  &:before {
    content: $icon-Voucher-Amount;
  }
}
.icon-Voucher-Expire {
  &:before {
    content: $icon-Voucher-Expire;
  }
}
.icon-Voucher-Generated {
  &:before {
    content: $icon-Voucher-Generated;
  }
}
.icon-Wallet {
  &:before {
    content: $icon-Wallet;
  }
}
.icon-Warning {
  &:before {
    content: $icon-Warning;
  }
}
.icon-Work-ID {
  &:before {
    content: $icon-Work-ID;
  }
}
