/** Font Families Importing **/
@import "../abstracts/mixins";

:root {
  --english-main-font: "Mulish";
  --arabic-main-font: "Almarai";
}

/* English */
@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 200;
  src: url("../../assets/fonts/Mulish/Mulish-Light.ttf");
}
@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/Mulish/Mulish-Regular.ttf");
}
@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/Mulish/Mulish-Bold.ttf");
}
@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  src: url("../../assets/fonts/Mulish/Mulish-ExtraBold.ttf");
}

/* Arabic */
@font-face {
  font-family: "Almarai";
  font-style: normal;
  font-weight: 200;
  src: url("../../assets/fonts/almarai/Almarai-Light.ttf");
}
@font-face {
  font-family: "Almarai";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/almarai/Almarai-Regular.ttf");
}
@font-face {
  font-family: "Almarai";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/almarai/Almarai-Bold.ttf");
}
@font-face {
  font-family: "Almarai";
  font-style: normal;
  font-weight: 800;
  src: url("../../assets/fonts/almarai/Almarai-ExtraBold.ttf");
}

/** FONTS **/
:root {
  --ion-font-family: "Mulish";
}

[dir="rtl"] {
  --ion-font-family: "Almarai";
}

$min_width: 320px;
$max_width: 1200px;

h1 {
  @include fluid-type($min_width, $max_width, 30px, 32px);
}

h2 {
  @include fluid-type($min_width, $max_width, 30px, 32px);
}

h3 {
  @include fluid-type($min_width, $max_width, 20px, 22px);
}

h4 {
  @include fluid-type($min_width, $max_width, 16px, 18px);
}

h5 {
  @include fluid-type($min_width, $max_width, 14px, 16px);
}

h6 {
  @include fluid-type($min_width, $max_width, 9px, 11px);
}

p,
li {
  @include fluid-type($min_width, $max_width, 14px, 16px);
}

.fw-400 {
  font-weight: 400;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.fw-bold {
  font-weight: bold;
}
.fz-10 {
  // font-size: 3.1vw;
  font-size: 10px;
}
.fz-12 {
  font-size: 12px;
}
.fz-14 {
  // font-size: 4.3vw;
  font-size: 14px;
}
.fz-16 {
  // font-size: 5vw;
  font-size: 16px;
}
.fz-18 {
  font-size: 18px;
}
.fz-20 {
  // font-size: 6.1vw;
  font-size: 20px;
}
.fz-24 {
  font-size: 24px;
}
.fz-28 {
  // font-size: 8.3vw;
  font-size: 28px;
}
.fz-30 {
  // font-size: 9.15vw;
  font-size: 30px;
}

.fz-38 {
  // font-size: 9.15vw;
  font-size: 38px;
}

.lh-23 {
  line-height: 23px;
}
.lh-45 {
  line-height: 45px;
}

/** Text Types **/
/**
Type of Text:
- Header Text classes:
  - cta-header
  - main-header
  - secondary-header
- Body Text classes
  - body-main-bold
  - body-main-regular
  - body-secondary-bold
  - body-seconday-regular
- Tag text class
  - tag-text
**/

// LTR Header
%header {
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
}
.cta-header {
  @extend %header;
  font-size: 30px;
}
.main-header {
  @extend %header;
  font-size: 24px;
  font-feature-settings: "pnum" on, "lnum" on;
}
.secondary-header {
  @extend %header;
  font-size: 20px;
  font-feature-settings: "pnum" on, "lnum" on;
}

//LTR body
%body {
  font-style: normal;
  line-height: 150%;
}
%main {
  font-size: 16px;
}
%secondary {
  font-size: 14px;
}
.main-body-bold {
  @extend %body;
  @extend %main;
  font-weight: 800;
}
.main-body-regular {
  @extend %body;
  @extend %main;
  font-weight: normal;
  line-height: 130%;
}
.secondary-body-bold {
  @extend %body;
  @extend %secondary;
  font-weight: bold;
}

.secondary-body-regular {
  @extend %body;
  @extend %secondary;
  font-weight: normal;
}

//LTR Tag Text
.tag-text {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 130%;
}

[dir="rtl"] {
  //RTL Header
  .cta-header,
  .main-header,
  .secondary-header {
    line-height: 150%;
  }
  .main-header {
    font-feature-settings: unset;
  }
  .secondary-header {
    font-feature-settings: "liga" off;
  }
  //RTL body
  .main-body-bold {
    font-weight: bold;
  }
  .main-body-regular {
    line-height: 150%;
  }
}
