body.dark {

  /** primary **/
  --ion-color-primary:#8896EC;
  --ion-color-primary-rgb: 136, 150, 236;
  --ion-color-primary-contrast: #1C1C22;
  --ion-color-primary-contrast-rgb: 28, 28, 34;
  --ion-color-primary-shade: #5F6DB9;
  --ion-color-primary-tint: #B8C3FE;
  --ion-color-primary-bright: #434551;
  --ion-color-primary-brighter: #25252D;

  /** secondary **/
  --ion-color-secondary: #A78AE5;
  --ion-color-secondary-rgb: 167, 138, 229;
  --ion-color-secondary-contrast: #1C1C22;
  --ion-color-secondary-contrast-rgb: 28, 28, 34;
  --ion-color-secondary-shade: #8A68D4;
  --ion-color-secondary-tint: #CEB8FF;
  --ion-color-secondary-bright: #69578E;
  --ion-color-secondary-brighter: #3D3252;

  /** tertiary **/
  --ion-color-tertiary: #CBB015;
  --ion-color-tertiary-rgb:203, 176, 21;
  --ion-color-tertiary-contrast: #1C1C22;
  --ion-color-tertiary-contrast-rgb: 28, 28, 34;
  --ion-color-tertiary-shade: #B09602;
  --ion-color-tertiary-tint: #E5D271;
  --ion-color-tertiary-bright: #635C36;
  --ion-color-tertiary-brighter: #45412B;

  /** success **/
  --ion-color-success: #39AB82;
  --ion-color-success-rgb: 57, 171, 130;
  --ion-color-success-contrast: #E9E9F6;
  --ion-color-success-contrast-rgb:233, 233, 246;
  --ion-color-success-shade: #28775A;
  --ion-color-success-tint: #40C997;
  --ion-color-success-bright: #276749;
  --ion-color-success-brighter: #2B463C;

  /** danger **/
  --ion-color-danger: #CA6060;
  --ion-color-danger-rgb: 202, 96, 96;
  --ion-color-danger-contrast: #E9E9F6;
  --ion-color-danger-contrast-rgb: 233, 233, 246;
  --ion-color-danger-shade: #B14343;
  --ion-color-danger-tint: #E28181;
  --ion-color-danger-bright: #672828;
  --ion-color-danger-brighter: #422A2A;

  /** warning **/
  --ion-color-warning: #D6C043;
  --ion-color-warning-rgb: 214, 192, 67;
  --ion-color-warning-contrast: #1C1C22;
  --ion-color-warning-contrast-rgb: 28, 28, 34;
  --ion-color-warning-shade: #B5A026;
  --ion-color-warning-tint: #E5D271;
  --ion-color-warning-bright: #635C36;
  --ion-color-warning-brighter: #45412B;

  /** dark - base color only used **/
  --ion-color-dark: #E9E9F6;
  --ion-color-dark-rgb: 233, 233, 246;
  --ion-color-dark-contrast: #1C1C22;
  --ion-color-dark-contrast-rgb: 28, 28, 34;
  /* --ion-color-dark-shade: #DBDBF0; //not used
  --ion-color-dark-tint: #F8F8FC; //not used */

  /** medium **/
  --ion-color-medium: #617080;
  --ion-color-medium-rgb: 97, 112, 128;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #4E5066;
  --ion-color-medium-tint: #A6B0BB;

  /** light **/
  --ion-color-light: #1C1C22;
  --ion-color-light-rgb: 28, 28, 34;
  --ion-color-light-contrast: #F8F8FC;
  --ion-color-light-contrast-rgb: 248, 248, 252;
  --ion-color-light-shade: #000000;
  --ion-color-light-shade-rgb: 0, 0, 0;
  --ion-color-light-tint: #353541;
  
  --ion-background-color : #1C1C22;
  --ion-item-color : #E9E9F6;
}
