:root {
  /** COLORS **/
  --card-box-shadow: 0px 2px 8px rgba(226, 226, 243, 0.5),
    0px 2px 8px rgba(226, 226, 243, 0.47);

  --primary-box-shadow: 0px 2px 25px rgba(69, 93, 229, 0.35);

  /** primary **/
  --ion-color-primary: #566ce6;
  --ion-color-primary-rgb: 69, 93, 229;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #2942cc;
  --ion-color-primary-tint: #7287fe;
  --ion-color-primary-bright: #adb7f8;
  --ion-color-primary-brighter: #e4e8fd;

  /** secondary **/
  --ion-color-secondary: #7b3dff;
  --ion-color-secondary-rgb: 123, 61, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #661fff;
  --ion-color-secondary-tint: #9d70ff;
  --ion-color-secondary-bright: #d9c7ff;
  --ion-color-secondary-brighter: #f3edff;

  /** tertiary **/
  --ion-color-tertiary: #ffd900;
  --ion-color-tertiary-rgb: 255, 217, 0;
  --ion-color-tertiary-contrast: #252940;
  --ion-color-tertiary-contrast-rgb: 37, 41, 64;
  --ion-color-tertiary-shade: #debd03;
  --ion-color-tertiary-tint: #ffe96b;
  --ion-color-tertiary-bright: #fff3af;
  --ion-color-tertiary-brighter: #fffadd;

  /** success **/
  --ion-color-success: #11d38d;
  --ion-color-success-rgb: 17, 211, 141;
  --ion-color-success-contrast: #252940;
  --ion-color-success-contrast-rgb: 37, 41, 64;
  --ion-color-success-shade: #009e65;
  --ion-color-success-tint: #39f1ae;
  --ion-color-success-bright: #b8e4d4;
  --ion-color-success-brighter: #edfbf6;

  /** danger **/
  --ion-color-danger: #e54545;
  --ion-color-danger-rgb: 229, 69, 69;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cc2929;
  --ion-color-danger-tint: #fe6666;
  --ion-color-danger-bright: #ffc7c7;
  --ion-color-danger-brighter: #fce8e8;

  /** warning **/
  --ion-color-warning: #ffe96b;
  --ion-color-warning-rgb: 255, 233, 107;
  --ion-color-warning-contrast: #252940;
  --ion-color-warning-contrast-rgb: 37, 41, 64;
  --ion-color-warning-shade: #ffd900;
  --ion-color-warning-tint: #fff6c7;
  --ion-color-warning-bright: #f6de60;
  --ion-color-warning-brighter: #fff9db;

  /** dark - base color only used **/
  --ion-color-dark: #252940;
  --ion-color-dark-rgb: 37, 41, 64;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #151c45; //not used
  --ion-color-dark-tint: #2f3552; //not used

  /** medium **/
  --ion-color-medium: #617080;
  --ion-color-medium-rgb: 97, 112, 128;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #4e5066;
  --ion-color-medium-tint: #a6b0bb;

  /** light **/
  --ion-color-light: #f7f7ff;
  --ion-color-light-rgb: 247, 247, 255;
  --ion-color-light-contrast: #252940;
  --ion-color-light-contrast-rgb: 37, 41, 64;
  --ion-color-light-shade: #e9e9f6;
  --ion-color-light-shade-rgb: 233, 233, 246;
  --ion-color-light-tint: #ffffff;

  /** white **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #455de5;
  --ion-color-white-contrast-rgb: 69, 93, 229;
  --ion-color-white-shade: #ffffff;
  --ion-color-white-tint: #ffffff;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.primary-color {
  color: var(--ion-color-primary);
  --color: var(--ion-color-primary);
}
.primary-bg {
  background-color: var(--ion-color-primary);
  --background-color: var(--ion-color-primary);
}
.success-color {
  color: var(--ion-color-success);
  --color: var(--ion-color-success);
}
.success-bg {
  background-color: var(--ion-color-success);
  --background-color: var(--ion-color-success);
}
.medium-color {
  color: var(--ion-color-medium);
  --color: var(--ion-color-medium);
}
.dark-color {
  color: var(--ion-color-dark);
  --color: var(--ion-color-dark);
}
.dark-bg {
  background-color: var(--ion-color-dark);
  --background-color: var(--ion-color-dark);
}
.light-color {
  color: var(--ion-color-light);
  --color: var(--ion-color-light);
}
.light-bg {
  background-color: var(--ion-color-light);
  --background-color: var(--ion-color-light);
}
.danger-color {
  color: var(--ion-color-danger);
  --color: var(--ion-color-danger);
}
.danger-shade-color {
  color: var(--ion-color-danger-shade);
  --color: var(--ion-color-danger-shade);
}
.danger-brighter {
  background-color: var(--ion-color-danger);
  --background-color: var(--ion-color-danger);
}
.gray-color {
  color: var(--ion-color-medium-tint);
  --color: var(--ion-color-medium-tint);
}
.ion-color-light-color {
  color: var(--ion-color-light);
  --color: var(--ion-color-light);
}
.transparent-bg {
  background-color: transparent;
  --background-color: transparent;
  --background: transparent;
}
.medium-shade-color {
  color: var(--ion-color-medium-shade);
  --color: var(--ion-color-medium-shade);
}
