/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "abstracts/functions";
@import "abstracts/variables";
@import "abstracts/placeholders";
@import "abstracts/mixins";
@import "abstracts/queires";
@import "base/animation";
@import "base/reset";
@import "base/typography";
@import "base/colors";
@import "base/modal";
@import "layout/grid";
@import "themes/light";
@import "themes/dark";
