/* #region  Toast Css */
.default-snack-bar,
.default-snack-bar-of-two-CTA {
  // To get default snack bar styles (add any new classes here)
  font-weight: 400;
  font-size: 14px;
  --border-radius: 12px;
  --min-height: 6.5vh;
  --width: fit-content;
  --max-width: 90%;
  color: var(--ion-color-light-tint);
}

.default-snack-bar {
  --background: var(--ion-color-dark);
}

.default-snack-bar-of-one-button {
  transform: translateY(
    -20vw
  ) !important; // to avoid overlaying over fixed bottom one button
}

.default-snack-bar-of-two-CTA {
  transform: translateY(-45vw) !important;
}

.default-snack-bar-button {
  --color: var(--ion-color-primary-tint);
}
/* #endregion */

/* #region  Modals Css */
.custom-modal {
  --width: 86%;
  --border-radius: 18px;
  --overflow: visible;
  --height: auto;
  --backdrop-opacity: 0.8 !important;

  &.success {
    --width: 53%;
    .modal-container {
      padding: 0px;
    }
  }

  .ion-page {
    overflow: visible;
    position: relative;
    contain: content;
    display: inline;
  }

  ion-backdrop {
    background-color: rgba(#000, 0.55);
  }
}

.custom-action-sheet {
  --overflow: visible;
  --backdrop-opacity: 0.8 !important;
  --border-radius: 16px 16px 0px 0px;
  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;
  }

  &::part(content) {
    position: fixed !important;
    bottom: 0;
    overflow-y: scroll;
  }

  ion-backdrop {
    background-color: rgba(#000, 0.55);
  }
}

@media screen and (min-width: 992px) {
  .custom-modal {
    --width: auto;
  }
}
/* #endregion */
